import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Card, Spinner } from 'reactstrap';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { PageTitle, Button } from '@upshop/exodia';
import { GetTenantStatusResponse, SetGoldPriceParams } from 'redux/slices/tenant/types';
import Maintenance from './Components/Maintenance';
import GoldPriceSettings from './Components/GoldPriceSettings';

import styles from './styles';

interface SettingsProps {
    getTenantStatus: () => void;
    tenantStatus: GetTenantStatusResponse;
    tenantStatusLoading: boolean;
    setMaintenance: (isMaintenance: boolean) => void;
    setGoldPrice: (goldPrice: SetGoldPriceParams) => void;
    loading: boolean;
    error: string;
}

const Settings = (props: SettingsProps): JSX.Element => {
    const { getTenantStatus, tenantStatus, tenantStatusLoading, setMaintenance, setGoldPrice, loading, error } = props;
    const { goldPrice, isMaintenance } = tenantStatus;

    const [maintenanceMode, setMaintenanceMode] = useState(isMaintenance);
    const [gold750, setGold750] = useState(goldPrice.gold750);
    const [gold916, setGold916] = useState(goldPrice.gold916);
    const [gold999, setGold999] = useState(goldPrice.gold999);
    const [gold9999, setGold9999] = useState(goldPrice.gold9999);

    useEffect(() => {
        getTenantStatus();
    }, []);

    useEffect(() => {
        if (goldPrice.gold750 !== gold750) setGold750(goldPrice.gold750);
        if (goldPrice.gold916 !== gold916) setGold916(goldPrice.gold916);
        if (goldPrice.gold999 !== gold999) setGold999(goldPrice.gold999);
        if (goldPrice.gold9999 !== gold9999) setGold9999(goldPrice.gold9999);
    }, [goldPrice]);

    useEffect(() => {
        if (isMaintenance !== maintenanceMode) setMaintenanceMode(isMaintenance);
    }, [isMaintenance]);

    const hasChanges = () => {
        if (isMaintenance !== maintenanceMode) return true;
        if (gold750 !== goldPrice.gold750 || gold916 !== goldPrice.gold916 || gold999 !== goldPrice.gold999 || gold9999 !== goldPrice.gold9999) return true;
        return false;
    };

    const handleSaveClick = () => {
        if (isMaintenance !== maintenanceMode) setMaintenance(maintenanceMode);
        if (gold750 !== goldPrice.gold750 || gold916 !== goldPrice.gold916 || gold999 !== goldPrice.gold999 || gold9999 !== goldPrice.gold9999) {
            setGoldPrice({
                gold750: Number(gold750),
                gold916: Number(gold916),
                gold999: Number(gold999),
                gold9999: Number(gold9999),
            });
        }
    };

    if (tenantStatusLoading) return <Spinner />;

    const buttonDisabled = !hasChanges();

    return (
        <div style={{ margin: '35px' }}>
            <div style={{ marginLeft: '30px', marginBottom: '20px' }}>
                <PageTitle size='xl'>Settings</PageTitle>
            </div>
            <Card style={styles.cardStyle}>
                <Maintenance maintenanceSwitch={maintenanceMode} setMaintenanceSwitch={setMaintenanceMode} />
                <GoldPriceSettings
                    gold750={gold750}
                    gold916={gold916}
                    gold999={gold999}
                    gold9999={gold9999}
                    setGold750={setGold750}
                    setGold916={setGold916}
                    setGold999={setGold999}
                    setGold9999={setGold9999}
                />
                <Button
                    primary
                    label='Save'
                    onClick={handleSaveClick}
                    disabled={buttonDisabled}
                    loading={loading}
                />
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    tenantStatusLoading: Selectors.getTenantTenantStatusAttempting(state),
    tenantStatus: Selectors.getTenantTenantStatus(state),
    loading: Selectors.getTenantSetMaintenanceAttempting(state) || Selectors.getTenantSetGoldPriceAttempting(state),
    error: Selectors.getTenantSetGoldPriceError(state) || Selectors.getTenantSetMaintenanceError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setMaintenance: (isMaintenance: boolean) => dispatch(Actions.tenantSetMaintenanceAttempt({ isMaintenance })),
    setGoldPrice: (goldPrice: SetGoldPriceParams) => dispatch(Actions.tenantSetGoldPriceAttempt({ ...goldPrice })),
    getTenantStatus: () => dispatch(Actions.tenantGetTenantStatusAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
