import React, { useState } from 'react';
import { MenuItem } from '@upshop/exodia';

import { Outlet, useNavigate } from 'react-router-dom';

const NavMenu = (): JSX.Element => {
    const [isSelected, setIsSelected] = useState('');
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex' }}>
            <div style={styles.container}>
                {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src='logo.png' alt='logo' style={{ width: 'auto', height: '150px', marginTop: '20px', marginBottom: '50px' }} />
                </div> */}

                {/* <div>
                    <MenuItem
                        sourceIcon='Logos/cart.svg'
                        itemLabel='Orders'
                        hasBadge={false}
                        badgeContent=''
                        isSelected={isSelected === 'Orders'}
                        onClick={() => {
                            setIsSelected('Orders');
                            navigate('/order');
                        }}
                    />
                </div> */}

                <div style={{ bottom: '10px', marginTop: 100 }}>
                    <MenuItem
                        sourceIcon='Logos/cog.svg'
                        itemLabel='Settings'
                        hasBadge={false}
                        badgeContent=''
                        isSelected={isSelected === 'Settings'}
                        onClick={() => {
                            setIsSelected('Settings');
                            navigate('/settings');
                        }}
                    />
                    <MenuItem
                        sourceIcon='Logos/logout.svg'
                        itemLabel='Sign Out'
                        hasBadge={false}
                        badgeContent=''
                        isSelected={isSelected === 'Sign Out'}
                        onClick={() => {
                            setIsSelected('Sign Out');
                            navigate('/login');

                            // TODO: handle signout
                        }}
                    />
                </div>
            </div>

            <Outlet />
        </div>
    );
};

const styles = {
    container: {
        width: '367px',
        height: '100vh',
        backgroundColor: '#F8F8F8',
        padding: '10px',
        justifyContent: 'center',
        display: 'block',
    },
};

export default NavMenu;
