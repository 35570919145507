import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import Routes from 'navigation/Routes';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

interface FullProps {
    loading: boolean;
    error: string;
    startup: () => void;
}

const Full = (props: FullProps): JSX.Element => {
    const { loading, error, startup } = props;

    useEffect(() => {
        startup();
    }, []);

    if (loading) return <Spinner />;
    // react-loading-skeleton not compatible with babel loader 8.1, exploring other options

    if (error) {
        // nav to error page
    }

    return (
        <>
            <Routes />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthStartupAttempting(state),
    error: Selectors.getAuthStartupError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startup: () => dispatch(Actions.authStartupAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Full);
