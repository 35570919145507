const dev = {
    upshopBaseUrl: 'https://kapchai-api.upshop.my',
};

const prod = {
    upshopBaseUrl: 'https://api.upshop.my',
};

const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default {
    ...config,
};
