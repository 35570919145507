import config from 'config';

import AuthGateway from './Auth';

import TenantGateway from './Tenant';

const baseUrl = config.upshopBaseUrl as string;

const auth = new AuthGateway(baseUrl);
const tenant = new TenantGateway(baseUrl);

export default {
    auth,
    tenant,
};
