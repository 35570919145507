import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetTenantStatusResponse, SetGoldPriceParams, SetMaintenanceParams } from './types';

const initialState = {
    actions: {
        tenantStatus: false,
        setMaintenance: false,
        setGoldPrice: false,
    },
    tenantStatus: {
        isMaintenance: false,
        goldPrice: {
            gold750: 0,
            gold916: 0,
            gold999: 0,
            gold9999: 0,
        },
    },
    error: {
        tenantStatus: '',
        setMaintenance: '',
        setGoldPrice: '',
    },
};

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        tenantGetTenantStatusAttempt: (state) => {
            state.actions.tenantStatus = true;
            state.error.tenantStatus = '';
        },
        tenantGetTenantStatusSuccess: (state, action: PayloadAction<GetTenantStatusResponse>) => {
            state.actions.tenantStatus = false;
            if (action.payload) state.tenantStatus = action.payload;
        },
        tenantGetTenantStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.tenantStatus = false;
            if (action.payload) state.error.tenantStatus = action.payload;
        },
        tenantSetMaintenanceAttempt: (state, _action: PayloadAction<SetMaintenanceParams>) => {
            state.actions.setMaintenance = true;
            state.error.setMaintenance = '';
        },
        tenantSetMaintenanceSuccess: (state, action: PayloadAction<SetMaintenanceParams>) => {
            state.actions.setMaintenance = false;
            if (action.payload) state.tenantStatus.isMaintenance = action.payload.isMaintenance;
        },
        tenantSetMaintenanceFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setMaintenance = false;
            if (action.payload) state.error.setMaintenance = action.payload;
        },
        tenantSetGoldPriceAttempt: (state, _action: PayloadAction<SetGoldPriceParams>) => {
            state.actions.setGoldPrice = true;
            state.error.setGoldPrice = '';
        },
        tenantSetGoldPriceSuccess: (state, action: PayloadAction<SetGoldPriceParams>) => {
            state.actions.setGoldPrice = false;
            if (action.payload) state.tenantStatus.goldPrice = action.payload;
        },
        tenantSetGoldPriceFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setGoldPrice = false;
            if (action.payload) state.error.setGoldPrice = action.payload;
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: tenantSlice.actions,
    reducers: tenantSlice.reducer,
};
