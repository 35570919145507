import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import NavActions from 'services/Navigation/Actions';

export default function* watchStartup(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup, api);
}

function* handleStartup(api: AuthGateway) {
    const authToken = Utils.Auth.getAuthToken();

    if (authToken) {
        yield put(Actions.authLoginSuccess(authToken));
        // Set authToken to redux
    } if (!authToken) {
        // NavActions.navToLogin();
    }

    yield put(Actions.authStartupSuccess());
}
