import React, { useState } from 'react';
import { PageTitle } from '@upshop/exodia';

const OrderDetails = (): JSX.Element => {
    return (
        <div style={{ margin: '35px', width: '100%' }}>
            Test
        </div>
    );
};

export default OrderDetails;
