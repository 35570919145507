import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import LoginScreen from 'containers/auth/Login';
import SettingsScreen from 'containers/settings';
import OrderScreen from 'containers/order';
import OrderDetailsScreen from 'containers/order/OrderDetails';
import DashboardScreen from 'containers/dashboard';

import PrivateRoute from './PrivateRoute';
import NavMenu from './Components/NavMenu';

const PrivateBucket = (): JSX.Element => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route element={<NavMenu />}>
                <Route
                    index
                    element={<DashboardScreen />}
                />
                <Route
                    path='/settings'
                    element={<SettingsScreen />}
                />

                <Route
                    path='/order'
                    element={<OrderScreen />}
                />

                <Route
                    path='/orderdetails'
                    element={<OrderDetailsScreen />}
                />
            </Route>
        </Route>
    );
};

const NavRoutes = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<LoginScreen />} />

                <Route path='*' element={<Navigate replace to='/' />} />
                {PrivateBucket()}
            </Routes>
        </BrowserRouter>
    );
};

export default NavRoutes;
