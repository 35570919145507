import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import { LoginActionPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authLoginAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: LoginActionPayload) {
    const { username, password, navigate } = data.payload;

    const response = yield* call([api, api.login], { userId: username, password });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.authLoginFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data?.token) {
            yield put(Actions.authLoginSuccess(response.data.token));
            Utils.Auth.storeAuthToken(response.data.token);
            navigate('/');
        } if (!response.data?.token) {
            yield put(Actions.authLoginFailure('Something went wrong. Please try again'));
        }
    }
}
