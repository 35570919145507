import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { ToggleSwitch } from '@upshop/exodia';

interface MaintenanceModeSettingsProps {
    maintenanceSwitch: boolean;
    setMaintenanceSwitch: (mode: boolean) => void;
}

const MaintenanceModeSettings = (props: MaintenanceModeSettingsProps): JSX.Element => {
    const { maintenanceSwitch, setMaintenanceSwitch } = props;

    return (
        <Container style={{ padding: '15px 0px', marginLeft: '30px' }}>
            <Row style={{ width: '350px', alignItems: 'center' }}>
                <Col>
                    <div style={{ color: 'blue', display: 'block' }}>Maintenance Mode</div>
                </Col>
                <Col>
                    <ToggleSwitch isOn={maintenanceSwitch} onClick={() => setMaintenanceSwitch(!maintenanceSwitch)} />
                </Col>
            </Row>
        </Container>
    );
};

export default MaintenanceModeSettings;
