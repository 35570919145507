import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Selectors from 'redux/Selectors';

const PrivateRoute = (): JSX.Element => {
    const isAuthenticated = useSelector(Selectors.getAuthAuthToken);

    if (!isAuthenticated) return <Navigate replace to='/login' />;

    return (
        <div style={{ display: 'flex' }}>
            <Outlet />
        </div>
    );
};

export default PrivateRoute;
