import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import TenantGateway from 'api/Tenant';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';

export default function* watchGetTenantStatus(api: TenantGateway): SagaWatcherReturnType {
    yield takeEvery('tenant/tenantGetTenantStatusAttempt', handleGetTenantStatus, api);
}

function* handleGetTenantStatus(api: TenantGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    if (!authToken) {
        yield put(Actions.tenantGetTenantStatusFailure('Not authorized!'));
        return;
    }

    const response = yield* call([api, api.getTenantStatus], { authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.tenantGetTenantStatusFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(Actions.tenantGetTenantStatusSuccess(response.data));
            return;
        }
    }

    yield put(Actions.tenantGetTenantStatusFailure('Something went wrong. Please try again later'));
}
