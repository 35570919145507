import React, { useState } from 'react';
import { PageTitle, Dropdown as DropDown, SearchBar, Button } from '@upshop/exodia';
import { Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import Table from './Components/Table';

const Order = (): JSX.Element => {
    const [dateDropdown, setDateDropdown] = useState(false);
    const [dogDropdown, setDogDropdown] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(false);

    const [selectedItem, setSelectedItem] = useState('');
    const [query, setQuery] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');
    const [selectedAdditionalFilter, setSelectedAdditionalFilter] = useState('');

    const tableHeaders = [<div>header</div>, <div style={{ color: '#6989FE' }}>header 2</div>, 'test', 'test', 'test', 'test', 'test', 'test'];
    const tableData = [[<div>data</div>, '1', '1', '1', '1', '1', '1', '1'], ['2', '2', '2', <button type='button'>button</button>, '2', '2', '2', '2']];

    const dropdownList = [
        'Datepicker',
        'Lorem ipsum dolor sit amet ',
        'test',
        'Pending Payment Verification',
    ];

    const filterList = [
        'Pending Payment',
        'Pending Payment Verification',
        'Payment Failed',
        'Payment Processing',
        'Paid (unfulfilled)',
        'Shipped',
        'Refunded',
    ];

    const handleOnKeyDown = (e: any) => {
        setQuery(e.currentTarget.value);

        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
        }
    };

    const handleSearch = () => {
        // handle search
        console.log('test search: ', query);
    };

    const renderDropdownList = () => {
        return dropdownList.map(list => {
            return (
                <DropdownItem key={list} onClick={() => setSelectedItem(list)} style={{ maxWidth: '300px', overflowX: 'hidden', overflowY: 'auto' }}>{list}</DropdownItem>
            );
        });
    };

    const renderFilterList = () => {
        return filterList.map(filter => {
            return (
                <DropdownItem key={filter} onClick={() => setSelectedAdditionalFilter(filter)}>{filter}</DropdownItem>
            );
        });
    };

    return (
        <div style={{ margin: '35px', width: '100%' }}>
            <div style={{ marginLeft: '30px', marginBottom: '80px' }}>
                <PageTitle size='xl'>Orders</PageTitle>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '13px' }}>
                <div style={{ marginRight: '15px', marginLeft: 'auto' }}>
                    <Dropdown isOpen={dateDropdown} toggle={() => setDateDropdown(!dateDropdown)}>
                        <DropdownToggle style={{ maxWidth: '300px', overflow: 'hidden' }} caret>
                            {selectedItem || 'Date'}
                        </DropdownToggle>
                        <DropdownMenu end>
                            {renderDropdownList()}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div>
                    <DropDown onLabelClick={() => setDogDropdown(!dogDropdown)} label='Dropdown for cute doggo' dropdown={dogDropdown} setDropdown={setDogDropdown}>
                        <img src='logo.png' alt='doggo' />
                    </DropDown>
                </div>
            </div>

            <Card style={{ padding: '20px', border: '1px solid rgb(0,0,0,0.1)', borderRadius: '15px', boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)', minWidth: '1200px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        {/* <SearchBar onClick={() => handleSearch()} label onChange={(e) => handleOnKeyDown(e)} /> */}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilter === 'All' ? 'rgba(105, 137, 254, 0.1)' : 'rgb(255,255,255)',
                                width: 'auto',
                            }}
                            label='All'
                            onClick={() => setSelectedFilter('All')}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilter === 'Fulfilled' ? 'rgba(105, 137, 254, 0.1)' : 'rgb(255,255,255)',
                                width: 'auto',
                            }}
                            label='Fulfilled'
                            onClick={() => setSelectedFilter('Fulfilled')}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilter === 'Unfulfilled' ? 'rgba(105, 137, 254, 0.1)' : 'rgb(255,255,255)',
                                width: 'auto',
                            }}
                            label='Unfulfilled'
                            onClick={() => setSelectedFilter('Unfulfilled')}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilter === 'Completed' ? 'rgba(105, 137, 254, 0.1)' : 'rgb(255,255,255)',
                                width: 'auto',
                            }}
                            label='Completed'
                            onClick={() => setSelectedFilter('Completed')}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilter === 'Cancelled' ? 'rgba(105, 137, 254, 0.1)' : 'rgb(255,255,255)',
                                width: 'auto',
                            }}
                            label='Cancelled'
                            onClick={() => setSelectedFilter('Cancelled')}
                        />
                    </div>
                </div>

                <div style={{ marginRight: '15px', marginLeft: 'auto' }}>
                    <Dropdown isOpen={filterDropdown} toggle={() => setFilterDropdown(!filterDropdown)}>
                        <DropdownToggle style={{ backgroundColor: 'transparent', color: '#4E6DDD', border: '1px solid rgba(0,0,0,0.1)', maxWidth: '300px', overflow: 'hidden' }} caret>
                            {selectedAdditionalFilter || 'Additional filters'}
                        </DropdownToggle>
                        <DropdownMenu end>
                            {renderFilterList()}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div style={{ marginTop: '10px' }}>
                    <Table tableHeaders={tableHeaders} tableData={tableData} loading={false} />
                </div>
            </Card>
        </div>
    );
};

export default Order;
