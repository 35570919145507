import { InitState } from 'redux/slices/auth';

const getLoginAttempting = (state: InitState): boolean => state.actions.login || false;
const getLoginError = (state: InitState): string => state.error.login || '';

const getAuthToken = (state: InitState): string => state.authToken || '';

const getStartupAttempting = (state: InitState): boolean => state.actions.startup || false;
const getStartupError = (state: InitState): string => state.error.startup || '';

export default {
    getLoginAttempting,
    getLoginError,

    getAuthToken,

    getStartupAttempting,
    getStartupError,
};
