import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import TenantGateway from 'api/Tenant';

import Actions from 'redux/Actions';
import { SetMaintenanceParams } from 'redux/slices/tenant/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchSetMaintenace(api: TenantGateway): SagaWatcherReturnType {
    yield takeEvery('tenant/tenantSetMaintenanceAttempt', handleSetMaintenance, api);
}

function* handleSetMaintenance(api: TenantGateway, data: PayloadAction<SetMaintenanceParams>) {
    const { isMaintenance } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setMaintenance], { isMaintenance, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.tenantSetMaintenanceFailure(response.message));
        return;
    }

    yield put(Actions.tenantSetMaintenanceSuccess({ isMaintenance }));
}
