import { fork } from 'redux-saga/effects';

import api from 'api';

import auth from './auth';
import tenant from './tenant';
import { SagaForkReturnType } from './types';

export default function* root(): SagaForkReturnType {
    yield fork(auth(api.auth).rootSaga);
    yield fork(tenant(api.tenant).rootSaga);
}
