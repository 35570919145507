import React from 'react';
import { Navigate } from 'react-router-dom';

const Dashboard = (): JSX.Element => {
    return (
        <Navigate to='/settings' replace />
    );
};

export default Dashboard;
