import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface GetTenantStatusParams {
    authToken: string;
}

export interface GetTenantStatusResponse {
    isMaintenance: boolean;
    goldPrice: {
        gold750: number;
        gold916: number;
        gold999: number;
        gold9999: number;
    }
}

export interface SetGoldPriceParams {
    gold750: number;
    gold916: number;
    gold999: number;
    gold9999: number;
    authToken: string;
}

export interface SetGoldPriceResponse {
    response: string;
}

export interface SetMaintenanceParams {
    isMaintenance: boolean;
    authToken: string;
}

export abstract class ITenantGateway extends Gateway {
    abstract getTenantStatus(params: GetTenantStatusParams): GatewayResponse<GetTenantStatusResponse | null>;

    abstract setGoldPrice(params: SetGoldPriceParams): GatewayResponse<null | null>;

    abstract setMaintenance(params: SetMaintenanceParams): GatewayResponse<null | null>;
}
