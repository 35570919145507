import { InitState } from 'redux/slices/tenant';
import { GetTenantStatusResponse } from './types';

const getTenantStatusAttempting = (state: InitState): boolean => state.actions.tenantStatus || false;
const getTenantStatusError = (state: InitState): string => state.error.tenantStatus || '';
const getTenantStatus = (state: InitState): GetTenantStatusResponse => state.tenantStatus || {};

const getSetMaintenanceAttempting = (state: InitState): boolean => state.actions.setMaintenance || false;
const getSetMaintenanceError = (state: InitState): string => state.error.setMaintenance || '';

const getSetGoldPriceAttempting = (state: InitState): boolean => state.actions.setGoldPrice || false;
const getSetGoldPriceError = (state: InitState): string => state.error.setGoldPrice || '';

export default {
    getTenantStatusAttempting,
    getTenantStatusError,
    getTenantStatus,

    getSetMaintenanceAttempting,
    getSetMaintenanceError,

    getSetGoldPriceAttempting,
    getSetGoldPriceError,
};
