import React, { useState } from 'react';
import { Container, Col, Row, Card } from 'reactstrap';
import { Input, EditButton } from '@upshop/exodia';

import styles from './styles';

interface GoldPriceSettingsProps {
    gold750: number;
    gold916: number;
    gold999: number;
    gold9999: number;
    setGold750: (e: any) => void;
    setGold916: (e: any) => void;
    setGold999: (e: any) => void;
    setGold9999: (e: any) => void;
}

const GoldPriceSettings = (props: GoldPriceSettingsProps): JSX.Element => {
    const { gold750, gold916, gold999, gold9999, setGold750, setGold916, setGold999, setGold9999 } = props;

    const [gold750Enabled, setGold750Enabled] = useState(false);
    const [gold916Enabled, setGold916Enabled] = useState(false);
    const [gold999Enabled, setGold999Enabled] = useState(false);
    const [gold9999Enabled, setGold9999Enabled] = useState(false);

    const renderEditButton = (visible: boolean, onClick: () => void) => {
        if (!visible) return false;

        return (
            <Col style={styles.goldEditButton}>
                <EditButton onClick={onClick} />
            </Col>
        );
    };

    return (
        <Card style={styles.goldCardStyle}>
            <Container>
                <Col>
                    <Row style={{ paddingBottom: '15px' }}>
                        <div style={{ color: 'blue' }}>Setting Prices (Real Time)</div>
                    </Row>
                    <Row style={{ paddingBottom: '15px' }}>
                        <Col>
                            <Input
                                disabled={!gold750Enabled}
                                label='Gold 750'
                                value={gold750.toString()}
                                onChange={(e) => setGold750(e.currentTarget.value)}
                            />
                        </Col>
                        {renderEditButton(!gold750Enabled, () => setGold750Enabled(!gold750Enabled))}
                    </Row>
                    <Row style={{ paddingBottom: '15px' }}>
                        <Col>
                            <Input
                                disabled={!gold916Enabled}
                                label='Gold 916'
                                value={gold916.toString()}
                                onChange={(e) => setGold916(e.currentTarget.value)}
                            />
                        </Col>
                        {renderEditButton(!gold916Enabled, () => setGold916Enabled(!gold916Enabled))}
                    </Row>
                    <Row style={{ paddingBottom: '15px' }}>
                        <Col>
                            <Input
                                disabled={!gold999Enabled}
                                label='Gold 999'
                                value={gold999.toString()}
                                onChange={(e) => setGold999(e.currentTarget.value)}
                            />
                        </Col>
                        {renderEditButton(!gold999Enabled, () => setGold999Enabled(!gold999Enabled))}
                    </Row>

                    {/* <Row style={{ paddingBottom: '15px' }}>
                        <Col>
                            <Input
                                disabled={!gold9999Enabled}
                                label='Gold 999.9'
                                value={gold9999.toString()}
                                onChange={(e) => setGold9999(e.currentTarget.value)}
                            />
                        </Col>
                        {renderEditButton(!gold9999Enabled, () => setGold9999Enabled(!gold9999Enabled))}
                    </Row> */}
                </Col>
            </Container>
        </Card>
    );
};

export default GoldPriceSettings;
