import { fork } from 'redux-saga/effects';

import TenantGateway from 'api/Tenant';
import { RootSagaReturnType } from 'sagas/types';

import watchGetTenantStatus from './getTenantStatus';
import watchSetMaintenace from './setMaintenance';
import watchSetGoldPrice from './setGoldPrice';

export default (api: TenantGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetTenantStatus, api);
        yield fork(watchSetMaintenace, api);
        yield fork(watchSetGoldPrice, api);
    }

    return {
        rootSaga,
    };
};
