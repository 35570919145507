import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import TenantGateway from 'api/Tenant';

import Actions from 'redux/Actions';
import { SetGoldPriceParams } from 'redux/slices/tenant/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchSetGoldPrice(api: TenantGateway): SagaWatcherReturnType {
    yield takeEvery('tenant/tenantSetGoldPriceAttempt', handleSetGoldPrice, api);
}

function* handleSetGoldPrice(api: TenantGateway, data: PayloadAction<SetGoldPriceParams>) {
    const { gold750, gold916, gold999, gold9999 } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.setGoldPrice], { gold750, gold916, gold999, gold9999, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.tenantSetGoldPriceFailure(response.message));
        return;
    }

    yield put(Actions.tenantSetGoldPriceSuccess({ gold750, gold916, gold999, gold9999 }));
}
