import auth from 'redux/slices/auth/Selectors';
import tenant from 'redux/slices/tenant/Selectors';
import { GetTenantStatusResponse } from './slices/tenant/types';

import { RootState } from './store';

const getAuthLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const getAuthLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);
const getAuthStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const getAuthStartupError = (state: RootState): string => auth.getStartupError(state.auth);

const getTenantTenantStatusAttempting = (state: RootState): boolean => tenant.getTenantStatusAttempting(state.tenant);
const getTenantTenantStatusError = (state: RootState): string => tenant.getTenantStatusError(state.tenant);
const getTenantTenantStatus = (state: RootState): GetTenantStatusResponse => tenant.getTenantStatus(state.tenant);

const getTenantSetMaintenanceAttempting = (state: RootState): boolean => tenant.getSetMaintenanceAttempting(state.tenant);
const getTenantSetMaintenanceError = (state: RootState): string => tenant.getSetMaintenanceError(state.tenant);

const getTenantSetGoldPriceAttempting = (state: RootState): boolean => tenant.getSetGoldPriceAttempting(state.tenant);
const getTenantSetGoldPriceError = (state: RootState): string => tenant.getSetGoldPriceError(state.tenant);

export default {
    getAuthLoginAttempting,
    getAuthLoginError,

    getAuthAuthToken,
    getAuthStartupAttempting,
    getAuthStartupError,

    getTenantTenantStatusAttempting,
    getTenantTenantStatusError,
    getTenantTenantStatus,

    getTenantSetMaintenanceAttempting,
    getTenantSetMaintenanceError,

    getTenantSetGoldPriceAttempting,
    getTenantSetGoldPriceError,
};
